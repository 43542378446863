import { gql } from '@apollo/client'
import Router from '../shared/router'
import Decimal from 'decimal.js'
import { ChargebeeCoupon } from '../utils/coupon'
import { PlanSellingPoint } from '../type'
import { Item } from '../shared/types/chargebee'

const PlanCheckMarkIcon = require('~assets/images/icons/plan-check-mark.svg')

export interface IExtraFeature {
  id: string
  title: string
  description: string
  price: number
  imageUrl: string
  chargebeeId: string
  position: number
}

export const queryPlan = gql`
  query ($id: ID!) {
    plan(id: $id) {
      id
      name: externalName
      description
      price
      period
      periodUnit
      pricingModel
      currencyCode
      trialPeriod
      trialPeriodUnit
      billingCycles
      freeQuantity
      availableCurrencies
      itemFamily {
        name
      }
      item {
        id
        users
        featuresList
        metadata
      }
      __typename
    }
  }
`

export const queryPostCheckoutUpsells = gql`
  query(
    $checkoutSubscriptionId: ID!, $checkoutId: ID!, $planId: ID!,
    $periodUnit: Integer, $period: Integer, $currency: String
  ) {
    upgradeCredits
    availableUpsells(checkoutSubscriptionId: $checkoutSubscriptionId)
    checkoutConfiguration(id: $checkoutId) {
      freeMonths exclusiveUpsellLimit
      cartExtraFeatures(planId: $planId, checkout: false) {
        title description headline imageUrl chargebeeId fakePrice couponApplicable theme customCoupon terminalOffer
      }
    }
    addons(periodUnit: $periodUnit, period: $period, currency: $currency, upgrades: true) {
      id name: externalName itemId itemType period periodUnit price item { featuresList }
    }
  }
`

export const mutationSubscribeMutation = `
mutation($input: SubscribeInput!) {
  result: subscribe(input: $input) { token subscriptionId finalPrice }
}`

export const mutationPaypalMutation = `
mutation(
  $id: ID!, $email: String!, $activateNow: Boolean, $coupon: String,
  $addons: [String!], $returnPath: String, $currency: String
) {
  token: paypalToken(
    id: $id,
    email: $email,
    activateNow: $activateNow,
    coupon: $coupon,
    addons: $addons,
    currency: $currency,
    returnPath: $returnPath,
  )
}`

export const createPaymentIntentMutation = `
mutation($amount: Int!, $method: String!, $currency: String) {
  createPaymentIntent(amount: $amount, method: $method, currency: $currency)
}`

export const attachAddon = gql`
  mutation($ids: [ID!]!, $coupons: [String!]!, $currency: String) {
    attachAddon(ids: $ids, coupons: $coupons, currency: $currency)
  }
`
export const applyCoupon = gql`
  mutation($code: String!, $priceId: ID, $currency: String) {
    coupon: redeemCoupon(code: $code, itemPriceId: $priceId, currency: $currency) {
      id
      name
      discountType
      discountPercentage
      discountAmount
    }
  }
`
export const getCouponDetail = gql`
  mutation($code: String!) {
    coupon: redeemCoupon(code: $code) {
      id
      name
      durationType
      discountType
      discountPercentage
      discountAmount
    }
  }
`

export const validateEmailQuery = gql`
  query($email: String!) { result: validateEmail(email: $email) }
`

export const createCheckoutSubscription = gql`
  mutation($affiliateId: ID, $planId: ID) {
    createCheckoutSubscription(affiliateId: $affiliateId) {
      id
      checkoutConfiguration {
        id
        title
        template
        freeMonths
        defaultCoupon
        payNowEnabled
        reducedMoneyBackGuarantee
        allowApplyCoupon
        seasonalDiscountBanner
        orderSummaryDrawer
        regularUpsellLimit
        validateEmail
        checkoutSteps {
          id
          stepType
          position
        }
        cartSteps {
          id
          stepType
          position
        }
        cartExtraFeatures(planId: $planId) {
          id
          title
          description
          imageUrl
          chargebeeId
        }
        cartPaymentMethods {
          id
          paymentMethod
          position
        }
      }
    }
  }
`

export const queryUpsellItems = gql`
  query addons($periodUnit: Integer, $period: Integer, $currency: String) {
    addons(periodUnit: $periodUnit, period: $period, currency: $currency) {
      id
      name: externalName
      itemId
      period
      periodUnit
      price
    }
  }
`

export type PayPalReturnData = { result: string, email: string, coupon?: string, activateNow: boolean, addons?: string[] }

// Generate a PayPal return data object
export const parsePayPalReturnData = (): PayPalReturnData => {
  const param: string[] = ((Router.qs.paypal as string) || '').split('|')
  if (!param.length) { return { result: '', email: '', activateNow: false } }

  const [email, coupon, activateNow, ...rest] = param
  const result = (rest.pop() as string)
  const addons = (rest.length > 1 || !!rest[0]) ? rest : undefined

  return { result, email: email.replace(/ /g, '+'), coupon, activateNow: activateNow === '1', addons }
}

// Calculate discount
export const calculateCouponDiscount = (basePrice: Decimal, coupon?: ChargebeeCoupon): number => {
  if (!coupon?.discountType) {
    return 0
  } else if (coupon.discountType === 'FIXED_AMOUNT') {
    return Number(coupon.discountAmount)
  } else {
    return basePrice.mul(new Decimal(coupon.discountPercentage || 0)).toNumber()
  }
}

export const calculatePriceAfterCoupon = (basePrice: number, coupon?: ChargebeeCoupon) : number => {
  const priceDecimal = new Decimal(basePrice)
  return priceDecimal.minus(calculateCouponDiscount(priceDecimal, coupon)).toNumber()
}

// Calculate a list of features
const DEFAULT_FEATURES = [
  '24/7 Helpful Support',
  'Unlimited Devices',
  'VPN / Internet Security',
]
export const generateFeaturesList = (
  { featuresList }: Pick<Item, 'featuresList'>,
  activateNow: boolean,
  freeTrialText = '7 Day Free Trial',
): PlanSellingPoint[] => {
  const result: PlanSellingPoint[] = []

  // Free trial
  if (!activateNow) {
    result.push({ icon: PlanCheckMarkIcon, title: freeTrialText })
  }

  // From admin input
  (featuresList || DEFAULT_FEATURES).forEach(feature => {
    const [title, subtitle] = feature.split(' / ')
    result.push({ icon: PlanCheckMarkIcon, title, subtitle })
  })

  return result
}

// Calculate the amount of free months
export const resolveFreeMonths = (hasPromo: boolean, config?: number): number => (
  (typeof config === 'number') ? config : (hasPromo ? 3 : 0)
)
