// @ts-ignore
import { action, computed, observable } from '@decorators'
import { runInAction } from 'mobx'

// @ts-ignore
import Query from '~shared/query'
// @ts-ignore
import Mutation from '~shared/mutation'
import { User, Currencies } from '../types'
import Cookies from 'universal-cookie'
import { Settings } from '../../config'

import Storage from '../storages'
import Router from '../router'

type UserAttributes = 'token' | 'fullName' | 'firstName' | 'lastName' | 'email' | 'authyEnabled' | 'hasPassword' | 'id'
export type Profile = Pick<User, UserAttributes> & { account: { currency: Currencies } }
export type Affiliate = {
  affiliateId: string | null,
  clickId: string | null,
  pubId: string | null,
  offer: string | null,
}
type CurrentUser = { me: Profile }

const { I18N_ENABLED } = process.env
const GQL_FIELDS = 'id token fullName firstName lastName email authyEnabled hasPassword account { currency }'
const GQL_PROFILE = `{ me { ${GQL_FIELDS} } }`
const GQL_AUTHENTICATE = `mutation($user: AuthenticationInput!) { me: authenticate(user: $user) { ${GQL_FIELDS} } }`

const cookies = new Cookies()

export default class Session extends Storage {
  @observable
  static authenticated = false

  static query = new Query<CurrentUser>(GQL_PROFILE)

  static auth = new Mutation<CurrentUser>(GQL_AUTHENTICATE)

  static get cookieOptions() {
    return { path: '/', maxAge: 60 * 60 * 24 * 30, domain: window.location.hostname.split(/.*my\./).at(-1) }
  }

  static get accessToken(): string | null {
    return this.get('accessToken')
  }

  static set accessToken(value: string | null) {
    if(typeof(value) === 'string') {
      this.set('accessToken', value)
      cookies.set('accessToken', value, this.cookieOptions)
    } else {
      this.clearItem('accessToken')
      cookies.remove('accessToken')
    }
  }

  static get tapFiliateClickId(): string | null {
    return this.get('tapFiliateClickId')
  }

  static set tapFiliateClickId(value: string | null) {
    if(typeof(value) === 'string') {
      this.set('tapFiliateClickId', value)
    } else {
      this.clearItem('tapFiliateClickId')
    }
  }

  static get affiliate(): Affiliate {
    return {
      affiliateId: cookies.get('affiliateId') || null,
      clickId: cookies.get('clickId') || null,
      pubId: cookies.get('pubId') || null,
      offer: cookies.get('offer') || null,
    }
  }

  static set affiliate(value: Affiliate) {
    if (!value.affiliateId) {
      return
    }

    if (!this.affiliate.affiliateId || Settings.preferableAffiliates.includes(value.affiliateId)) {
      cookies.set('affiliateId', value.affiliateId || '', this.cookieOptions)
      cookies.set('clickId', value.clickId || '', this.cookieOptions)
      cookies.set('pubId', value.pubId || '', this.cookieOptions)
      cookies.set('offer', value.offer || '', this.cookieOptions)
    }
  }

  @computed
  static get currency(): string | undefined {
    return this.get('currency') || (this.authenticated ? this.profile?.account?.currency : undefined)
  }

  static set currency(value: string) {
    runInAction(() => this.set('currency', value))
  }

  @computed
  static get locale(): string | undefined {
    return (!I18N_ENABLED && 'en') || this.get('locale') || undefined
  }

  static set locale(value: string) {
    runInAction(() => this.set('locale', value))
  }

  @computed
  static get profile(): Profile | undefined {
    return this.query.data?.me
  }

  @computed
  static get authorize(): boolean | string {
    if(!this.authenticated) {
      return true
    } else if(this.profile && !this.profile.hasPassword) {
      return '/welcome'
    } else {
      return false
    }
  }

  @computed
  static get hasDashboardAccess(): boolean {
    return this.authorize === false
  }

  static loadAuth(): Promise<boolean> {
    if(typeof(this.accessToken) != 'string') {
      return this.loadQsAuth()
    }

    return new Promise<boolean>(resolver => {
      this.query.load().then(action(() => {
        if(this.profile) {
          this.authenticated = true
        }

        resolver(!!this.profile)
      }), action(() => {
        this.accessToken = null
        resolver(false)
      }))
    })
  }

  static loadQsAuth(): Promise<boolean> {
    if(!(Router.qs['email'] && Router.qs['T'])) {
      return Promise.resolve(false)
    }

    const operation = new Mutation<CurrentUser, { email: string, token: string }>(`
      mutation($email: String!, $token: String!) {
        me: tokenAuthenticate(email: $email, token: $token) { ${GQL_FIELDS} }
      }
    `)

    return new Promise<boolean>(resolver => {
      const email = (Router.qs['email'] || '').replace(/ /g, '+')
      operation.exec({ email, token: Router.qs['T'] }).then(action(() => {
        if(operation.data?.me) {
          this.update(operation.data.me)
          this.authenticated = true
          this.accessToken = (operation.data.me.token as string)
          this.clearItem('currency')
        }

        resolver(!!operation.data?.me)
      }), () => resolver(false))
    })
  }

  static authenticate(email: string, password: string, token?: string): Mutation<CurrentUser> {
    this.auth.exec({ user: { email, password, token } }).then(action(() => {
      if(this.auth.data?.me) {
        this.update(this.auth.data.me)
        this.authenticated = true
        this.accessToken = (this.auth.data.me.token as string)
        this.clearItem('currency')
      }
    }))

    return this.auth
  }

  static update(data: Partial<Profile>): void {
    const current = this.query.data?.me || {}
    const me = ({ ...current, ...data } as Profile)
    this.query.update({ me })

    const fcWidget = (window as any).fcWidget
    fcWidget?.user?.setFirstName(me.firstName)
    fcWidget?.user?.setEmail(me.email)
  }

  @action
  static clear(redirect?: string): void {
    cookies.remove('accessToken', this.cookieOptions)
    this.clearItem('accessToken')
    this.clearItem('currency')

    this.accessToken = null
    this.tapFiliateClickId = null
    this.authenticated = false

    this.query = new Query<CurrentUser>(GQL_PROFILE)
    this.auth = new Mutation<CurrentUser>(GQL_AUTHENTICATE)

    const fcWidget = (window as any).fcWidget
    fcWidget?.user?.setFirstName('')
    fcWidget?.user?.setEmail('')

    if(redirect) {
      window.location.pathname = redirect
    }
  }
}
